:global html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  box-sizing: border-box;
}
.app {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #eee;
}
.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
  height: 7rem;
}  
.buttons button {
  background-color: #ff1c70;
  color: #eee;
  font-size: 2rem;
  font-weight: 500;
  padding: 1rem 1.5rem;
  border-radius: 4rem;
}
