@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.board {
  height: 50vh;
  width: 50vh;
}
table.board {
  border-collapse: collapse;
  border-radius: 6px;
}
table.board td {
  border: 0.5rem solid black;
  height: 33%;
  width: 33%;
}
table.board tr:first-child td {
  border-top: 0;
}
table.board tr td:first-child {
  border-left: 0;
}
table.board tr:last-child td {
  border-bottom: 0;
}
table.board tr td:last-child {
  border-right: 0;
}
button.square.winningSquare {
  animation: spin 1.5s infinite linear;
}
button.square {
  display: inline-block;
  width: 99%;
  height: 99%;
  border: none;
  padding: 0px;
  margin: 0;
  background: inherit;
  color: black;
  font-family: sans-serif;
  font-size: 4rem;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
}
button.square:focus {
  outline-offset: -5px;
  outline-color: black;
}
