body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  box-sizing: border-box;
}
.App_app__1xy_S {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #eee;
}
.App_buttons__10DNt {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
  height: 7rem;
}  
.App_buttons__10DNt button {
  background-color: #ff1c70;
  color: #eee;
  font-size: 2rem;
  font-weight: 500;
  padding: 1rem 1.5rem;
  border-radius: 4rem;
}

@-webkit-keyframes BoardDisplay_spin__2P20w {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes BoardDisplay_spin__2P20w {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.BoardDisplay_board__2KCnn {
  height: 50vh;
  width: 50vh;
}
table.BoardDisplay_board__2KCnn {
  border-collapse: collapse;
  border-radius: 6px;
}
table.BoardDisplay_board__2KCnn td {
  border: 0.5rem solid black;
  height: 33%;
  width: 33%;
}
table.BoardDisplay_board__2KCnn tr:first-child td {
  border-top: 0;
}
table.BoardDisplay_board__2KCnn tr td:first-child {
  border-left: 0;
}
table.BoardDisplay_board__2KCnn tr:last-child td {
  border-bottom: 0;
}
table.BoardDisplay_board__2KCnn tr td:last-child {
  border-right: 0;
}
button.BoardDisplay_square__gjGKI.BoardDisplay_winningSquare__sw8Gw {
  -webkit-animation: BoardDisplay_spin__2P20w 1.5s infinite linear;
          animation: BoardDisplay_spin__2P20w 1.5s infinite linear;
}
button.BoardDisplay_square__gjGKI {
  display: inline-block;
  width: 99%;
  height: 99%;
  border: none;
  padding: 0px;
  margin: 0;
  background: inherit;
  color: black;
  font-family: sans-serif;
  font-size: 4rem;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
}
button.BoardDisplay_square__gjGKI:focus {
  outline-offset: -5px;
  outline-color: black;
}

